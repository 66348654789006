.steps-content {
    min-height: 200px;
    margin-top: 16px;
    padding-top: 80px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
}

.steps-action {
    margin-top: 24px;
}

.ant-steps-item-icon {
    background-color: #23282c !important;
    border-color: #23282c !important;
}

.ant-steps-icon {
    color: white !important;
}

.anticon-check {
    font-size: 19px;
}

.ant-upload-drag {
    height: 300px !important;
}
