@import '~antd/dist/antd.less';

@import "./components/drawer/drawer.scss";
@import "./components/form/form.scss";
@import "./components/loader/loader.scss";
@import "./components/navbar/navbar.scss";
@import "./components/video-player/video-player.scss";
@import "./modules/package-form/package-form.scss";
@import "./modules/packages/packages.scss";
@import "./modules/video/video.scss";
@import "./modules/vod-form/vod-form.scss";
@import "./modules/vod-meta/vod-meta.scss";
@import "./components/edit-team/edit-team.scss";

.form-input {
    .ant-input {
        border: none !important;
    }
}

.ant-input[disabled] {
    background-color: #fff !important;
    color: rgba(0, 0, 0, .85) !important;
}

.message-container {
    left: 50%;
}

.ant-message {
    position: fixed !important;
}

.package-form-modal .ant-modal-content {
    height: 550px;
}

.ant-tabs-tab {
    margin-right: 20px !important;
    border-radius: 16px !important;
}

.ant-tabs-tab-active {
    background-color: #2a2e49 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
    font-weight: 600 !important;
}

.flag-icon {
    font-size: 75px;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: #f5f5f5;
    ;
}

.match-card {
    border-radius: 10px;
}

.delay-form .ant-row {
    margin: 20px 0px !important;
}

.ant-modal-header {
    background-color: #2a2e49 !important;
}

.ant-modal-title {
    color: white !important;
}

.ant-modal-close-x {
    color: white !important;
}

.publish-modal .ant-modal-footer {
    display: flex;
    justify-content: center;
}

.layout-transition {
    transition: margin-left 0.3s ease 0s, margin-right 0.3s ease 0s, width 0.3s ease 0s, flex 0.3s ease 0s;
}


.drawer-left-margin {
    margin-left: 265px;
}

@media (max-width: 575.98px) {

    .drawer-left-margin {
        margin-left: 0px;
    }

}

.ant-list-item {
    padding: 0px !important;
}

.match-card .ant-card-body {
    display: flex !important;
    justify-content: space-between !important;
}

.ant-card-body::before {
    display: none;
}

.ant-card-body::after {
    display: none;
}

.page-form-modal {
    width: 600px !important;
}

.add-content-modal {
    width: 1000px !important;
}

.league-modal {
    width: 800px !important;
}

.fantasy-logs-modal {
    width: 70vw !important;
}

.fantasy-player-config-modal {
    width: 100% !important;
}

.ant-list-item-meta {
    display: flex !important;
    flex: 1 1 !important;
    align-items: center !important;
    max-width: 100% !important;
}

.bulk-upload-modal .ant-modal-content {
    height: 650px !important;
}

.story-picture-list {
    .ant-upload-list {
        overflow-y: auto !important;
        max-height: 250px !important;
        margin: 20px 0px !important;
    }
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff !important;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
    border-top: 2px dashed #1890ff !important;
}

.w-md-editor {
    height: 60vh !important;
}

.matchlist-spinner {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: white;
    border-radius: 4px;
}

.ant-descriptions-item-label {
    align-items: center !important;
}

.fantasy-table {
    .ant-table-filter-trigger {
        color: #9d9797;
    }

    .ant-table-filter-trigger.active {
        color: #2a2e49;
    }
}

.fantasy-table.unhovered-row {

    table:hover,
    tr:hover,
    thead:hover {
        background: none !important;
    }

    td.ant-table-cell-fix-left {
        background: inherit;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td {
        background: none; 

        .ant-checkbox-wrapper>.ant-checkbox {
            .ant-checkbox-checked .ant-checkbox-inner {
                pointer-events: none !important;
                border-color: none !important;
                outline: none !important;
                background-color: none !important;
            }

            &:focus,
            &:hover {
                background-color: none !important;
                border-color: none !important;
                outline: none !important;
                pointer-events: none !important;
            }
        }
    }
}

.livestream-modal {
    width: 650px !important;
}

.team-ids-card {
    .ant-card-body {
        padding: 0px 8px;
    }
}

.ant-badge-status-dot {
    height: 10px;
    width: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
@primary-color: #2a2e49;@link-color: #1890ff;