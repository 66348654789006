.form-container {
    border: none !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 500px !important;
    width: 50% !important;
    margin-top: 200px !important;
}

.form {
    display: flex !important;
    flex-direction: column !important;
    min-width: 0 !important;
    word-wrap: break-word !important;
    background-clip: border-box !important;
    border: none !important;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%) !important;
    border-radius: 6px !important;
}

.form .ant-row {
    margin: 20px !important;
}

.ant-input {

    .login-input {
        background-color: #ededed !important;
    }
    
}

.login-button {
    width: 100% !important;
    height: 42px !important;
    font-size: 16px !important;
    background-color: #2a2e49 !important;
    border-color: #2a2e49 !important;
    border-radius: 0 !important;
    color: #fff !important;
    opacity: .65 !important;
    padding: 8px 22px !important;
    border: none !important;
    box-shadow: 0 1px 2px rgb(0 0 0 / 15%) !important;
}

.ant-message {
    position: absolute !important;
}

.ant-message-custom-content {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}