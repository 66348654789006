body {
	margin: 0;
	font-family: -apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Roboto,
		'Helvetica Neue',
		Arial,
		'Noto Sans',
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji',
		'Segoe UI Symbol',
		'Noto Color Emoji';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #efefef !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

input:focus {
	outline: none !important;
}


.x-factor-highlight {
	background-color: yellow;
}

.x-factor-highlight:hover {
	background-color: yellow !important;
}

.x-factor-highlight:hover>td {
	background-color: yellow !important;
}

.white-bg,
.white-bg:hover td {
	background: #fff !important;
}