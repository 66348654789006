.menu {
    background-color: #23282c !important;
    color: white !important;
    list-style-type: none !important;
    display: flex !important;
    padding: 12px !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-bottom: 0px !important;

    li:first-child {
        flex: 1;
    }

    li:last-child {
        padding: 16px !important;
        border-radius: 8px !important;
        cursor: pointer !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.ant-menu-item {
    cursor: pointer !important;
    padding: 0px !important;
}

.ant-menu-item-selected {
    background-color: #23282c !important;
    color: white !important;
}

.ant-menu-item:hover {
    color: white !important;
}

.sports-selector .ant-select-selector {
    background-color: #212235 !important;
}

.sports-selector  .ant-select-arrow {
    color: white !important;
}

.sports-selector .ant-select-selection-item {
    color: white !important;
}
