.ant-drawer-content-wrapper {
    top: 74px !important
}

.ant-drawer-body {
    background-color: #23282c;
    color: white;
}

.ant-drawer-mask {
    background: none !important;
}

.ant-collapse {
    background-color: #23282c !important;
    border: none !important;
}

.ant-collapse-header {
    color: white !important;
}

.ant-collapse-content-box {
    background-color: #23282c;
}

.ant-collapse>.ant-collapse-item {
    border-bottom: none !important;
}

.ant-collapse-content {
    border-top: none !important;
}

.ant-menu-submenu-title {
    padding: 0px !important;
}

.ant-menu-submenu-title:hover {
    color: white !important;
}

.ant-menu {
    font-size: 18px !important;
    color: white !important;
    background-color: #23282c !important;
}

.ant-menu-sub {
    padding-left: 36px !important;
}

.ant-menu-submenu-arrow {
    color: white !important;
}

.ant-menu-inline {
    border-right: none !important;
}

.ant-menu-submenu-selected {
    color: white !important;
}

.ant-menu-item:after {
    border-right: none !important;
}